import React, {useState} from 'react';
import close from "../../asset/footer close.svg";
import {connect} from "react-redux";
import {push} from "connected-react-router";
import {getValue, setValue} from "../../store/session-storage-util";
import './Cookies.css'

const Cookies = ({push}) => {
    const [cookiesRead, setCookiesRead] = useState(getValue('cookiesRead') ||false);

    const markRead = () => {
        setCookiesRead(true);
        setValue('cookiesRead', true);
    };

    const goToTerms = () => {
        markRead();
        push('/tingimused')
    };

    return (
        <div className={'cookies'} style={cookiesRead ? {display: 'none'} : {}}>
            <div className={'cookies-text'}>
                Sellel veebilehel kasutatakse küpsiseid. Kasutamist jätkates nõustute <a href={"/tingimused"} style={{color: '#ffffff', textDecoration: 'underline', cursor: 'pointer'}} onClick={goToTerms}>andmekaitsetingimustega.</a>
            </div>
            <div className={'cookies-bottom'}>
                <img alt={''} src={close} style={{height: '34px'}} onClick={markRead}/>
                <button type={'button'} className={'btn cookies-button'} onClick={markRead}>NÕUSTUN</button>
            </div>
        </div>
    )
};

export default connect(null, {push})(Cookies);
