import React, {useState} from 'react';
import {Form, Formik} from "formik";
import {CheckBoxField, DateField, SimpleField} from "../form/simple-field";
import debt from "../../asset/noue.svg"
import browse from "../../asset/upload.svg"
import {FormHeader} from "./FormHeader";
import './Form.css';
import {push} from "connected-react-router";
import {connect} from "react-redux";
import {ProgressBar} from "../progress-bar/ProgressBar";
import {getJsonValue, getValue, setJsonValue} from "../../store/session-storage-util";
import {useDropzone} from "react-dropzone";


const ClaimForm = ({push}) => {
    const editingClaim = getValue('editingClaim');
    const savedValues = getJsonValue('claimForm');

    const initialValues = editingClaim && savedValues ?
        savedValues :
        {claims: []};

    const [claimsAmount, setClaimsAmount] = useState(editingClaim && savedValues ? savedValues.claims.length : 1);

    const {getInputProps, open, acceptedFiles} = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        noDrag: true
    });

    const files = acceptedFiles.map(file => (
            <li key={file.path}>
                {file.path}
            </li>
        ));

    const totalSum = (values) => {
      let sum = values.claims.map(c => Number(c.claimAmount) || 0).reduce((c1, c2) => c1 + c2, 0);
      let reductions = values.claims.map(c => Number(c.claimPaidAmount) || 0).reduce((c1, c2) => c1 + c2, 0);
      return sum - reductions;
    };

    return (
        <div>
            <ProgressBar index={2}/>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    let storedFiles = [];
                    acceptedFiles.forEach(file => {
                        const reader = new FileReader()

                        reader.onabort = () => console.log('file reading was aborted')
                        reader.onerror = () => console.log('file reading has failed')
                        reader.onload = () => {
                            // Do whatever you want with the file contents
                            const binaryStr = reader.result
                            storedFiles.push({fileName: file.path, content: binaryStr});
                            setJsonValue('storedFiles', storedFiles);
                        }
                        reader.readAsDataURL(file);
                    });
                    setJsonValue('claimForm', values);
                    push('/menetlustaotlus')
                }}
            >
                {({
                      values, isSubmitting, setFieldValue
                  }) => (
                    <Form>
                        {[...Array(claimsAmount).keys()].map((i) => {
                            const row = values.claims[i] || {};
                            return (
                                <>
                                <FormHeader icon={debt} text={`Nõue ${i+1}`}/>
                                <SimpleField name={`claims[${i}].contractNr`} label={'Arve/lepingu number*'} required/>
                                <DateField name={`claims[${i}].contractDate`} label={'Esitamise kuupäev*'} maxDate={new Date()} required value={row.contractDate} onChange={setFieldValue}/>
                                <DateField name={`claims[${i}].dueDate`} label={'Tasumise tähtaeg*'} required maxDate={new Date()} value={row.dueDate} onChange={setFieldValue}/>
                                <SimpleField name={`claims[${i}].claimAmount`} label={'Arve põhisumma (€)*'} required/>
                                <SimpleField name={`claims[${i}].claimPaidAmount`} label={'Tänaseks tasutud summa (€)*'} required/>
                                <span style={{marginBottom: '8px'}} />
                                <CheckBoxField name={`claims[${i}].overdue`} type={'checkbox'} label={'Arvel on viivis'}/>
                                </>
                            )
                        })}
                        <div className={'buttons-row'}>
                            <button className={'btn add-button'} type={'button'} disabled={claimsAmount >= 5} onClick={() => setClaimsAmount(claimsAmount + 1)}>
                                Lisa nõue
                            </button>
                            <button className={'btn remove-button'} type={'button'} disabled={claimsAmount <= 1} onClick={() => {
                                setClaimsAmount(claimsAmount - 1)
                            }}>
                                Eemalda nõue
                            </button>
                        </div>
                        <hr className={'horizontal-line'}/>
                        <div className={'field-title'}>
                            Põhinõude koondsumma (€)
                        </div>
                        <SimpleField name={'totalSum'} readOnly placeholder={totalSum(values)} />
                        <span style={{marginBottom: '22px'}} />
                        <hr className={'horizontal-line'}/>
                        <div className={'buttons-row pointer'} onClick={open}>
                            <input {...getInputProps()}/>
                            <span>
                                Laadi üles pilt / lisa nõude dokumendid
                            </span>
                            <img alt={''} src={browse} />
                        </div>
                        <hr className={'horizontal-line'}/>
                        <div>
                            <ul className={'files-list'}>{files}</ul>
                        </div>
                        <div style={{marginTop: '15px', display: 'inline-flex'}}>
                            <CheckBoxField name={'isConfirmed'} type={'checkbox'} label={'Kinnitan, et olen esitanud andmed ausalt ' +
                            'oma parimate teadmiste kohaselt ja olen' +
                            'teadlik, et tahtlik valeandmete esitamine ' +
                            'võib kaasa tuua vastutuse.'} required/>
                        </div>
                        <button className={'btn forward-button'} type={'submit'} disabled={isSubmitting}>
                            EDASI
                        </button>
                        <button className={'btn back-button'} type={'button'} disabled={isSubmitting} onClick={() => {
                            push('/menetlustaotlus/võlausaldaja')
                        }}>
                            TAGASI
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default connect(null, { push })(ClaimForm);
