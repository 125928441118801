import React, {useState} from 'react';
import {Form, Formik} from "formik";
import {AutocompleteField, SelectField, SimpleField} from "../form/simple-field";
import newUser from "../../asset/andmed.svg"
import address from "../../asset/aadress.svg"
import contact from "../../asset/sidevahendid.svg"
import {FormHeader} from "./FormHeader";
import './Form.css';
import {push} from "connected-react-router";
import {connect} from "react-redux";
import {ProgressBar} from "../progress-bar/ProgressBar";
import {getJsonValue, getValue, setJsonValue} from "../../store/session-storage-util";

const DebtorForm = ({push}) => {

    const editingDebtor = getValue('editingDebtor');
    const savedValues = getJsonValue('debtorForm');
    const [autofill, setAutofill] = useState(null);

    const detailsByPlaceId = (placeId, values) => {
        const places = new window.google.maps.places.PlacesService(document.createElement('div'));
        const request = {
            placeId,
            fields: ['address_component', 'name']
        };

        const { OK } = window.google.maps.places.PlacesServiceStatus;

        return new Promise((resolve, reject) => {
            places.getDetails(request, (results, status) => {
                if (status !== OK) {
                    return reject(status);
                }
                setAutofill({
                    ...values,
                    address: {
                        address: `${getAutofillProp(results, 'route')} ${getAutofillProp(results, 'street_number')}`,
                        county: getAutofillProp(results, 'administrative_area_level_1'),
                        city: getAutofillProp(results, 'locality'),
                        postalCode: getAutofillProp(results, 'postal_code')
                    }
                });
            });
        });
    };

    const getAutofillProp = (data, type) => {
        const res = data && data['address_components'].filter(row => row.types.includes(type))[0];
        return res ? res["long_name"] : '';
    }

    const initialValues = (editingDebtor && savedValues) ?
        savedValues : autofill ||
        {
            person: {
                personType: 'BUSINESS',
                registrationNr: ''
            },
            contacts: {
                phoneNr: '+372 '
            }
        };

    return (
        <div>
            <ProgressBar index={0}/>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={(values) => {
                    setJsonValue('debtorForm', values);
                    if (editingDebtor) {
                        push('/menetlustaotlus');
                    } else {
                        push('/menetlustaotlus/võlausaldaja');
                    }
                }}
            >
                {({
                      isSubmitting, values
                  }) => {
                    return (
                        <Form>
                            <FormHeader icon={newUser} text={'VÕLGNIKU ANDMED'}/>
                            <SelectField name={'person.personType'} label={'Isik*'}
                                         options={[
                                             {code: 'BUSINESS', value: 'Juriidiline isik'},
                                             {code: 'PRIVATE', value: 'Eraisik'}
                                             ]}/>
                            {values.person.personType === 'BUSINESS' ?
                                <>
                                    <SimpleField name={'person.registrationNr'} label={'Registrinumber*'} maxLength={"99999999"} required type={'number'}/>
                                    <SimpleField name={'person.companyName'} label={'Ettevõtte nimi*'} required/>
                                </> :
                                <>
                                    <SimpleField name={'person.personCode'} label={'Isikukood (selle puudumisel sünnikuupäev)'} />
                                    <SimpleField name={'person.firstName'} label={'Nimi*'} required/>
                                    <SimpleField name={'person.lastName'} label={'Perenimi*'} required/>
                                </>
                            }
                            <FormHeader icon={address} text={'AADRESS'}/>
                            <AutocompleteField label={'Eeltäida aadress'} name={'address.addressSearch'} onSelect={(data, values) => {
                                detailsByPlaceId(data, values);
                            }}/>
                            <SimpleField name={'address.address'} label={'Aadress'}/>
                            <SimpleField name={'address.postalCode'} label={'Postiindeks'} max={"99999"} type={'number'}/>
                            <SimpleField name={'address.city'} label={'Linn/Vald'}/>
                            <SimpleField name={'address.county'} label={'Maakond'}/>
                            <FormHeader icon={contact} text={'SIDEVAHENDID'}/>
                            <SimpleField name={'contacts.phoneNr'} label={`Telefon${(!values.contacts.email || values.contacts.email.length === 0) ? '*' : ''}`} type={'tel'} required={!values.contacts.email || values.contacts.email.length === 0} />
                            <SimpleField name={'contacts.email'} type={'email'} label={`E-post${(!values.contacts.phoneNr || values.contacts.phoneNr.length < 6) ? '*' : ''}`} required={!values.contacts.phoneNr || values.contacts.phoneNr.length < 6} />
                            <button className={'btn forward-button'} type={'submit'} disabled={isSubmitting}>
                                EDASI
                            </button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
};

export default connect(null, {push})(DebtorForm);
