import React, {useState} from 'react';
import {Field} from "formik";
import DatePicker from "react-datepicker/es";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

export const SimpleField = ({name, label, type, required, placeholder, readOnly, max, maxLength}) => {
    return <>
        {label &&
            <label className={'form-label'} htmlFor={name}>{label}</label>
        }
        <Field name={name} type={type} validate={required && validateRequired}>
            {({field, meta, form}) => {
                const hasError = meta.error && (meta.touched || form.submitCount > 0);
                return (
                    <input
                        {...field}
                        className={`form-input ${hasError ? 'field-required' : ''}`}
                        placeholder={placeholder}
                        type={type}
                        max={max}
                        maxLength={maxLength}
                        readOnly={readOnly}
                    />
                    )
            }}
        </Field>
    </>
};

export const PhoneField = ({name, label, type, required, placeholder, readOnly, max, maxLength}) => {
    const [hasError, setError] = useState(false);
    return <>
        {label &&
        <label className={'form-label'} htmlFor={name}>{label}</label>
        }
        <div className={`form-input ${hasError ? 'field-required' : ''}`}>
            <Field name={`${name}-prefix`} type={type} validate={required && validateRequired}>
                {({field}) => {
                    return (
                        <input {...field} autoComplete="off" className={'form-input-tel-prefix'} style={{display: 'inline-flex'}}/>
                    )
                }}
            </Field>
            <Field name={name} type={type} validate={required && validateRequired}>
                {({field, meta, form}) => {
                    const hasError = meta.error && (meta.touched || form.submitCount > 0);
                    setError(hasError);
                    return (
                        <input
                            {...field}
                            autoComplete="off"
                            className={`form-input-tel ${hasError ? 'field-required' : ''}`}
                            placeholder={placeholder}
                            type={type}
                            max={max}
                            maxLength={maxLength}
                            readOnly={readOnly}
                        />
                    )
                }}
            </Field>
        </div>
    </>
};

export const DateField = ({name, label, value, minDate, maxDate, onChange, required}) => {
    return (
        <>
            {label &&
                <label className={'form-label'} htmlFor={name}>{label}</label>
            }
            <Field name={name} type={'date'} validate={required && validateRequired}>
                {({meta, form}) => {
                    const hasError = meta.error && (meta.touched || form.submitCount > 0);
                    return (
                        <DatePicker
                            className={`form-input date-input ${hasError ? 'field-required' : ''}`}
                            onChange={(val) => {onChange(name, val)}}
                            selected={(value && new Date(value)) || null}
                            dateFormat={'dd.MM.yyyy'}
                            minDate={minDate}
                            maxDate={maxDate}
                        />
                    )
                }}
            </Field>

        </>
    )
};

export const CheckBoxField = ({name, label, required, readOnly, style}) => {
    return <Field name={name} validate={required && validateRequired}>
            {({field, meta, form}) => {
                const hasError = meta.error && (meta.touched || form.submitCount > 0);
                return (
                    <label className={`checkbox-container ${hasError ? 'field-required' : ''}`} {...field} style={style}>
                        <input
                            className={'checkbox-input'}
                            name={name}
                            value={field.value}
                            type={'checkbox'}
                            readOnly={readOnly}
                            checked={readOnly ? meta.initialValue : field.value}
                            onChange={field.onChange}
                        />
                        <div style={{display: 'flex'}} className={'checkbox-flex'}>
                            <div className={'checkbox-wrap'}>
                                <span className={`checkmark${readOnly ? ' readOnly' : ''}${hasError ? ' field-required' : ''}`}/>
                            </div>
                            <div>
                                <span className={`${readOnly ? 'readOnly' : ''} checkbox-text ${meta.error ? 'error-text' : ''}`}>{label}</span>
                            </div>
                        </div>
                    </label>
                    )
            }}
        </Field>
};

const validateRequired = (value) => {
    let error;
    if (value == null || value === false || value.length === 0 || value === '+372 ') {
        error = 'Kohtustuslik väli';
    }
    return error;
};

export const SelectField = ({name, label, options, required}) => {

    return <>
        {label &&
            <label className={'form-label'} htmlFor={name}>{label}</label>
        }
        <Field className={'form-input select-input'} as={'select'} name={name} validate={required && validateRequired}>
            {options && options.map((option) => {
                return <option key={option.code} value={option.code}>{option.value}</option>
            })}
        </Field>
    </>
};

export const AutocompleteField = ({name, label, options, required, onSelect}) => {
    const menuStyle = {
        borderRadius: '3px',
        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
        background: 'rgba(255, 255, 255, 0.9)',
        padding: '2px 0',
        fontSize: '90%',
        overflow: 'auto',
        zIndex: '10'
    };

    return <>
        {label &&
        <label className={'form-label'} htmlFor={name}>{label}</label>
        }
        <Field className={'form-input select-input'} as={'select'} name={name} validate={required && validateRequired}>
            {({field, meta, form}) => {
                return (
                    <GooglePlacesAutocomplete
                        autocompletionRequest={{
                            componentRestrictions: {
                                country: 'ee'
                            }
                        }}
                        inputClassName={'form-input select-input'}
                        inputStyle={{display: 'flex'}}
                        onSelect={val => {
                            form.setFieldValue(name, val);
                            onSelect(val.place_id, form.values);
                        }}
                        placeholder={''}
                        suggestionsStyles={{container: menuStyle}}
                        value={field.value}
                    />
                )}}
        </Field>
    </>
};
